<template>

  <nav>
  <div class="p-2 max-w-screen-xl flex flex-wrap justify-between mx-auto">
    <div class="flex h-full">
      <a href="/" class="block text-t1">
        <img src="/img/logo-img.png" class="h-10 lg:h-12" alt="Logo" />
      </a>
    </div>
    <button @click="navbarMenuToggle" data-collapse-toggle="navbar-default" type="button" class="inline-flex text-lg text-t2 rounded-lg lg:hidden" aria-controls="navbar-default" aria-expanded="false">
      <svg class="w-10 h-10" aria-hidden="true" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
    </button>
      <!-- Collapsed menu -->
   <div class="hidden w-full lg:block lg:w-auto text-t2 text-base" id="navbar-default">
      <ul class="flex flex-col pt-4 lg:pt-1 lg:flex-row lg:space-x-6">
        <li class="py-1">
          <a href="/schools" class="text-t1" aria-current="page">For Schools</a>
        </li>
        <li class="py-1">
          <a href="/highered" class="text-t1" aria-current="page">For Higher Education</a>
        </li>
        <li class="py-1">
          <a href="/about" class="text-t1" aria-current="page">About</a>
        </li>
        <li class="lg:hidden block py-0">
          <a class="text-t1" href="/contact"> Book a demo </a>
        </li>
        <li class="hidden lg:block">
          <a class="text-white" href="/contact">
        <button class="bg-c6 hover:bg-c4 p-1 px-3 font-bold rounded-lg">
            Get in touch
        </button></a>
        </li>
      </ul>
    </div>
  </div>
</nav>

</template>

<script>
export default {
  name: 'App',
  data() {
    return {
    };
  },
  methods: {
    navbarMenuToggle() {
      const menu = document.getElementById('navbar-default');
      menu.classList.toggle('hidden');
    }
  }
};
</script>

